import React from 'react'
import { Region } from '@enonic/react-components';

import Columns from '../../../react4xp/components/ColumnsLayout';

function ColumnsLayout({ numberOfColumns, regions }) {

  return (
    <Columns
      columns={numberOfColumns}
      content1={<Region name={regions.column1.name} regionData={regions.column1} />}
      content2={numberOfColumns >= 2 ? <Region name={regions.column2.name} regionData={regions.column2} /> : null}
    />
  )
}

export default (props) => <ColumnsLayout {...props} />
